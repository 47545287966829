/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "44bits 팟캐스트 155번째 로그에서는 cron 캘린더 앱, 스택오버플로우 모노리스 시스템, 왓차 온보딩 프로세스에 대해서 이야기를 나누었습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://cron.com/"
  }, "Cron")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.hada.io/topic?id=6993"
  }, "StackOverflow는 9대의 on-prem 서버로 운영중 - GeekNews")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://hanselminutes.com/847/engineering-stack-overflow-with-roberta-arcoverde#"
  }, "Engineering Stack Overflow with Roberta Arcoverde - Hanselminutes Technology Podcast")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://medium.com/watcha/%EC%99%93%EC%B1%A0%EC%9D%98-%EC%98%A8%EB%B3%B4%EB%94%A9-%ED%94%84%EB%A1%9C%EC%84%B8%EC%8A%A4-e71b1cb1d278"
  }, "왓챠의 온보딩 프로세스 - Medium")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
